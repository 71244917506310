<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
    :id="dynamicID"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center childMenu">
      <img v-if="item.title == 'Overview'" src="@/assets/images/logo/Vector_1.png" alt="access-control"  style="max-width: 15px;" class="mr-1 ml-2"/>
      <img v-if="item.title == 'Local Posts'" src="@/assets/images/logo/Vector.png" alt="access-control"  style="max-width: 15px;" class="mr-1 ml-2"/>
      <img v-if="item.title == 'Triggers'" src="@/assets/images/logo/Vector.png" alt="access-control"  style="max-width: 20px;" class="mr-1"/>
      <img v-if="item.title == 'Access Control'" src="@/assets/images/logo/lock.png" alt="access-control"  style="max-width: 20px;" class="mr-1"/>
      <img  v-if="item.title == 'Stores'" src="@/assets/images/logo/stores.png" alt="access-control"  style="max-width: 15px;" class="mr-1 ml-2"/>
      <!-- <img v-if="item.title == 'All Stores'" src="@/assets/images/logo/stores.png" alt="access-control"  style="max-width: 20px;" class="mr-1"/>   -->
      <img v-if="item.title == 'GMB'" src="@/assets/images/logo/gmb2.png" alt="access-control"  style="max-width: 20px;" class="mr-1"/>
      <img v-if="item.title == 'GMB For Dealers'" src="@/assets/images/logo/stores.png" alt="access-control"  style="max-width: 20px;" class="mr-1"/>
      <img v-if="item.title == 'New UI'" src="@/assets/images/logo/Vector.png" alt="access-control"  style="max-width: 20px;" class="mr-1"/>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";

export default {
  components: {
    BLink,
    BBadge,
  },
  data() {
    return {
      imageUrl:"@/src/assets/images/logo/business.png",
      dynamicID: "custom-"+this.item.title
      // imageUrl: '@/assets/images/logo/business.png' // Replace with the actual image URL or path
    };
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    customIntialValue: {
      type: Boolean,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );

    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>
<style>
.custom-style {
  border: none !important;
}
.childMenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-menu.menu-light .navigation > li ul .active {
  background: unset !important;
  box-shadow: unset !important;
}
</style>
