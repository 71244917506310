<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-bind="linkPropsAvatar" class="d-flex align-items-center">
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
      <b-avatar
        :text="avatarText(item.title)"
        :variant="getRandomVariant(true)"
        class="mr-1"
      />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge, BAvatar } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";

export default {
  components: {
    BLink,
    BBadge,
    BAvatar,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive, linkPropsAvatar } =
      useVerticalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      linkPropsAvatar,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
  data() {
    return {
      variants: {
        0: "danger",
        1: "warning",
        2: "pink",
        3: "purple",
        4: "info",
        5: "dark",
      },
    };
  },
  methods: {
    getRandomVariant(light = false) {
      let randomSelect = Math.floor(Math.random() * 6);
      let variant = this.variants[randomSelect];
      if (light) {
        variant = "light-" + variant;
      }
      return variant;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
  },
};
</script>
