<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav
      class="nav user-details-nav align-items-center ml-auto profile-card"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
             {{ userDetails }}
            </p>
            <p class="user-email mb-0">{{ userEmail }}</p>
          </div>
          <b-avatar size="40" class="avtar-color" :text="getInitials" />
          <feather-icon size="30" icon="MoreVerticalIcon" class="mr-20" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          @click="openLogoutModal"
          link-class="d-flex align-items-center text-danger"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-modal
        no-close-on-backdrop
        id="modal-center"
        hide-footer
        hide-header
        centered
        size="small"
        v-model="logoutModal"
      >
        <b-card-text>
          <div
            class="header text-right"
            style="margin-top: -10px;margin-right:-18px"
          >
            <b-button
              variant="flat-primary"
              @click="closeLogoutModal"
              class="disable-hover"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
          <div>
            <h5 class="deletemodal-text">
              Are you sure want to Logout?
            </h5>
          </div>
          <div class="buttons">
            <b-button
              class="button-margin"
              style="width: 140px;"
              variant="outline-secondary"
              @click="closeLogoutModal"
            >
              Cancel
            </b-button>
            <b-button variant="danger" @click="logout" style="width: 140px;">
              Logout
            </b-button>
          </div>
        </b-card-text>
      </b-modal>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BModal,
  BCardText,
  BButton,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import {
  getUserData,
  getUserToken,
  getUserEmail,
} from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      logoutModal: false,
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BModal,
    BCardText,
    // Navbar Components
    DarkToggler,
    BButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userDetails: function() {
      return getUserData();
    },
    userEmail: function() {
      return getUserEmail();
    },
    getInitials: function() {
      var acronym = ""
      const userName = getUserData();
      if (userName) {
        var matches = userName.match(/\b(\w)/g);
        acronym = matches.join("");
      }
      return acronym;
    },
  },
  methods: {
    openLogoutModal() {
      this.logoutModal = true;
    },
    closeLogoutModal() {
      this.logoutModal = false;
    },
    logout() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log("logging out");
          useJwt.removeUserData();
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
          Cookies.remove(useJwt.jwtConfig.storageTokenKeyName);
          Cookies.remove(useJwt.jwtConfig.storageRefreshTokenKeyName);
          Cookies.remove(this.jwtConfig.userNameKey);
          Cookies.remove(this.jwtConfig.userEmailKey)
          this.$router.push({ name: "login" });
          this.closeLogoutModal();
        })
        .catch((error) => {
          console.log(error.response);
          useJwt.removeUserData();
          this.$router.push({ name: "login" });
        });
    },
  },
};
</script>
<style>
.profile-card {
  background-color: #f5f6fa;
}
.avtar-color {
  background-color: #f5edff !important;
}
.navbar[class*="bg-"] .navbar-nav .nav-item > a span {
  color: #9747ff !important;
}
.navbar[class*="bg-"] .navbar-nav .nav-item > a svg {
  color: #bbbbbb !important;
}
.deletemodal-text {
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #200e6b;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.buttons {
  margin-bottom: 30px;
  display: flex;
  gap: 30px;
  padding: 20px 20px;
  justify-content: center;
}

.button-margin {
  margin-right: 0;
}
.disable-hover {
  color: #bbbbbb;
}
</style>
