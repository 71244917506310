<template>
<div style="height: 2.5rem;">
  <p class="clearfix mb-0" style="margin-top: 40px;">
    <span class="float-md-right d-block d-md-inline-block">
      <b-link
        class="ml-25 text-danger"
        :to="`/privacy-policy/`"
        >Privacy Policy</b-link
      >, COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
      class="ml-25 text-danger"
      href="https://d2scale.in/"
      target="_blank"
      >D2Scale</b-link
    >
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    
  </p>
</div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
},
};
</script>
