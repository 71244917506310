// import store from "@/store";

let menu = [
    {
        header: 'Recently Visited Clients',
    },
    {
        title: 'Clients',
        icon: 'UsersIcon',
        route: 'client-list',
    },
];

// const menuClient = store.getters.client;

// if (menuClient.status) {
//     let menuClients = {
//         title: menuClient.name,
//         icon: 'UsersIcon',
//         children: []
//     };
//     for (let i = 0; i < menuClient.items.length; i++) {
//         const item = menuClient.items[i];
//         menuClients.children.push(
//             {
//                 title: item.title,
//                 route: item.to,
//             },
//         )
//     }
//     menu.push(menuClients);
// }

export default menu;