import { ref } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps, navLinkAvatarProps } from '@core/layouts/utils'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item);

  const linkPropsAvatar = navLinkAvatarProps(item);

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
    linkPropsAvatar
  }
}
